export const definitions = [
  {
    header: 'Personal Data',
    text: 'Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).',
  },
  {
    header: 'Usage Data',
    text: 'Usage Data is data collected automatically either generated by the use of the Website or from the Website infrastructure itself (for example, the duration of a page visit).',
  },
  {
    header: 'Cookies',
    text: `Cookies are small pieces of data stored on a User's device.`,
  },
  {
    header: 'Data Controller',
    text: 'Data Controller means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.',
  },
  {
    header: 'Data Processors (or Service Providers)',
    text: `Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.`,
  },
  {
    header: 'Data Subject',
    text: `Data Subject is any living individual who is the subject of Personal Data.`,
  },
  {
    header: 'User',
    text: `The User is the individual using our Website. The User corresponds to the Data Subject, who is the subject of Personal Data.`,
  },
]

export const typesOfDataCollected = [
  {
    header: 'Personal Data',
    text: `While using our Website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to: Name, Email address, Address, City, County, Postcode, Country.
    We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you, if you have opted in to receive such communication from us. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.`,
  },
  {
    header: 'Usage Data',
    text: `Usage Data
    We may also collect information on how the Website is accessed and used ("Usage Data"). This Usage Data may include information such as the pages of our Website that you visit, the time and date of your visit, the time spent on those pages and other diagnostic data.`,
  },
  {
    header: 'Tracking & Cookies Data',
    text: `We use cookies and similar tracking technologies to track the activity on our Website and hold certain information.
    Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Website.
    You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Website.
    Examples of Cookies we use: <b>session cookies</b> (we use these cookies to operate our Website) and <b>preference cookies</b> (we use these cookies to remember your preferences and various settings).`,
  },
]

export const useOfData = [
  {
    text: `To operate and maintain our Website`,
  },
  {
    text: `To allow you to participate in interactive features of our Website when you choose to do so`,
  },
  {
    text: `To provide customer support
    `,
  },
  {
    text: `To gather analysis or valuable information so that we can improve our Website`,
  },
  {
    text: `To monitor the usage of our Website`,
  },
  {
    text: `To detect, prevent and address technical issues`,
  },
  {
    text: `To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about, if you have opted in to receive such information`,
  },
]

export const disclosureOfData = [
  {
    header: `Business Transaction.`,
    text: `If we are involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.`,
  },
  {
    header: `Disclosure for Law Enforcement.`,
    text: `Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`,
  },
]

export const processingPersonalData = [
  {
    text: `We need to perform a contract with you`,
  },
  {
    text: `You have given us permission to do so`,
  },
  {
    text: `The processing is in our legitimate interests and it's not overridden by your rights`,
  },
  {
    text: `For payment processing purposes`,
  },
  {
    text: `To comply with the law`,
  },
]

export const dataProtectionRights = [
  {
    text: `<b>The right to access, update or to delete</b> the information we have on you.`,
  },
  {
    text: `<b>The right of rectification.</b> You have the right to have your information rectified if that information is inaccurate or incomplete.`,
  },
  {
    text: `<b>The right to object.</b> You have the right to object to our processing of your Personal Data.`,
  },
  {
    text: `<b>The right of restriction.</b> You have the right to request that we restrict the processing of your personal information.`,
  },
  {
    text: `<b>The right to data portability.</b> You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format.`,
  },
  {
    text: `<b>The right to withdraw consent.</b> You also have the right to withdraw your consent at any time where Areté Publishing relied on your consent to process your personal information.`,
  },
]
